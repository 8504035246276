(function($) {
    "use strict"; // Start of use strict

    // Offset for Main Navigation
    $('#mainNav').affix({
        offset: {
            top: 100
        }
    })

    /* Angebot Anfrage Validation
    ================================================== */

    $("#contact-form").validate({
        ignore: [],
        rules: {
            anrede: {
                required: true,
                minlength: 1
            },
            forename: {
                required: true,
                minlength: 2
            },
            name: {
                required: true,
                minlength: 2
            },
            email: {
                required: true,
                email: true
            },
            phone: {
                required: false,
                minlength: 2
            },
            company: {
                required: false,
                minlength: 1
            }
        },
        messages: {
            anrede: {
                required: "Bitte wählen Sie Ihre Anrede aus."
            },
            forename: {
                required: "Bitte geben Sie Ihren Vornamen ein.",
                minlength: "Ihr Vorname muss mindestens aus zwei Zeichen bestehen."
            },
            name: {
                required: "Bitte geben Sie Ihren Nachnamen ein.",
                minlength: "Ihr Nachname muss mindestens aus zwei Zeichen bestehen."
            },
            email: "Bitte geben Sie Ihre E-Mail-Adresse ein.",
            company: {
                required: "Bitte geben Sie Ihr Unternehmen ein."
            }
        },
        onfocusout: false,
        invalidHandler: function(form, validator) {
            var errors = validator.numberOfInvalids();
            if (errors) {
                validator.errorList[0].element.focus();
            }
        }
    });
    /*
     * Translated default messages for the jQuery validation plugin.
     * Locale: DE (German, Deutsch)
     */
    $.extend($.validator.messages, {
        required: "Dieses Feld ist ein Pflichtfeld.",
        maxlength: $.validator.format("Geben Sie bitte maximal {0} Zeichen ein."),
        minlength: $.validator.format("Geben Sie bitte mindestens {0} Zeichen ein."),
        rangelength: $.validator.format("Geben Sie bitte mindestens {0} und maximal {1} Zeichen ein."),
        email: "Geben Sie bitte eine gültige E-Mail Adresse ein.",
        url: "Geben Sie bitte eine gültige URL ein.",
        date: "Bitte geben Sie ein gültiges Datum ein.",
        number: "Geben Sie bitte eine Nummer ein.",
        digits: "Geben Sie bitte nur Ziffern ein.",
        equalTo: "Bitte denselben Wert wiederholen.",
        range: $.validator.format("Geben Sie bitte einen Wert zwischen {0} und {1} ein."),
        max: $.validator.format("Geben Sie bitte einen Wert kleiner oder gleich {0} ein."),
        min: $.validator.format("Geben Sie bitte einen Wert größer oder gleich {0} ein."),
        creditcard: "Geben Sie bitte eine gültige Kreditkarten-Nummer ein."
    });

    /* Parallax Effect
    ================================================================================ */
    $(window).on('load scroll', function() {
        var scrolled = $(this).scrollTop();
        $('.hero-img.parallax').css("background-position", "50% " + (scrolled / 2) + "px")
        $('.intro-text.fadeOut').css({
            'transform': 'translate3d(0, ' + -(scrolled * 0.2) + 'px, 0)', // parallax (20% scroll rate)
            //'opacity': 1 - scrolled / 300 // fade out at 400px from top
        });
        $('.scroll-down.fadeOut').css({
            'transform': 'translate3d(0, ' + -(scrolled * 0.2) + 'px, 0)', // parallax (20% scroll rate)
            'opacity': 1 - scrolled / 300 // fade out at 400px from top
        });
    });

    /* Video Overlay
    ================================================================================ */
    $('#play-video').on('click', function(e) {
        e.preventDefault();
        $('#video-overlay').addClass('open');
        $('.video-overlay .fillWidth .layerVideo').append('<video loop="true" autoplay="true" muted="true" controls="true" width="560" height="315"><source src="videos/01b_Palmoel.mp4" type="video/mp4" /></video>');
        //$('.video-overlay .fillWidth .layerVideo').append('<iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/Q6SlaXVd_Mw?rel=0&amp;controls=1&amp;autoplay=1&amp;showinfo=0" frameborder="0" allowfullscreen></iframe>');
        $('body').addClass('layered');
    });

    $('.video-overlay-close').on('click', function(e) {
        e.preventDefault();
        close_video();
    });


    /*  Accordion
    ================================================================================ */

    var CLASS_ACTIVE = 'active'
    var CLASS_OPEN = 'open'
    var $accordion = $('.js-accordion')

    $accordion.on('click', '.js-toggle', function(e) {
        var $item = $(e.currentTarget).parent()
        var $content = $item.find('.js-content')
        var isOpen = $item.hasClass(CLASS_OPEN)

        if (isOpen) {
            // close:
            $item.removeClass(CLASS_ACTIVE)
            $content.slideUp(function() {
                $item.removeClass(CLASS_OPEN)
                $content.show()
            })
        } else {
            // open:
            $item.addClass(CLASS_ACTIVE + ' ' + CLASS_OPEN)
            $content.hide().slideDown()
        }
    });

    /* Magnific Popup
    ================================================================================ */
    $('.inline-popups').magnificPopup({
        delegate: 'a',
        removalDelay: 500, //delay removal by X to allow out-animation
        callbacks: {
            beforeOpen: function() {
                this.st.mainClass = this.st.el.attr('data-effect');
            }
        },
        midClick: true // allow opening popup on middle mouse click. Always set it to true if you don't provide alternative source.
    });


    /* Replace all SVG images with inline SVG
    ================================================================================ */
    jQuery('img.svg').each(function() {
        var $img = jQuery(this);
        var imgID = $img.attr('id');
        var imgClass = $img.attr('class');
        var imgURL = $img.attr('src');

        jQuery.get(imgURL, function(data) {
            // Get the SVG tag, ignore the rest
            var $svg = jQuery(data).find('svg');

            // Add replaced image's ID to the new SVG
            if (typeof imgID !== 'undefined') {
                $svg = $svg.attr('id', imgID);
            }
            // Add replaced image's classes to the new SVG
            if (typeof imgClass !== 'undefined') {
                $svg = $svg.attr('class', imgClass + ' replaced-svg');
            }

            // Remove any invalid XML tags as per http://validator.w3.org
            $svg = $svg.removeAttr('xmlns:a');

            // Replace image with new SVG
            $img.replaceWith($svg);

        }, 'xml');

    });

    // /* Move background perspective on mouse move effect
    // ================================================== */

    // var lFollowX = 0,
    //     lFollowY = 0,
    //     x = 0,
    //     y = 0,
    //     friction = 1 / 30;

    // function moveBackground() {
    //     x += (lFollowX - x) * friction;
    //     y += (lFollowY - y) * friction;

    //     var translate = 'translate(' + x + 'px, ' + y + 'px) scale(1.1)';

    //     $('.hero-bg').css({
    //         '-webit-transform': translate,
    //         '-moz-transform': translate,
    //         'transform': translate
    //     });

    //     window.requestAnimationFrame(moveBackground);
    // }

    // $(window).on('mousemove click', function(e) {

    //     var lMouseX = Math.max(-100, Math.min(100, $(window).width() / 2 - e.clientX));
    //     var lMouseY = Math.max(-100, Math.min(100, $(window).height() / 2 - e.clientY));
    //     lFollowX = (20 * lMouseX) / 100; // 100 : 12 = lMouxeX : lFollow
    //     lFollowY = (10 * lMouseY) / 100;

    // });

    // moveBackground();

    /* Match height
    ================================================== */
    // $(".teaser-section").matchHeight({
    //     byRow: true,
    //     property: 'height',
    //     target: null,
    //     remove: false
    // });

    /* colio plugin
    ================================================== */
    // var colio_run = function() {
    //     $('.expand-box .wrap').colio({
    //         id: 'card-expand',
    //         theme: 'white',
    //         placement: 'after',
    //         expandLink: '.card-expand-link',
    //         scrollOffset: 250,
    //         syncScroll: true
    //     });
    // };

    // colio_run();

    /* Testimonial Slider
    ================================================================================ */
    $('.slider-testimonial').slick({
        dots: true,
        arrows: true,
        centerMode: true,
        autoplay: true,
        pauseOnHover: true,
        autoplaySpeed: 10000,
        focusOnSelect: false,
        infinite: true,
        fade: true,
        adaptiveHeight: false,
        //centerPadding: '10px',            
    });

    $('.slider-testimonial').on('beforeChange', function(event, slick, currentSlide, nextSlide) {
        $('.slider-testimonial .slick-dots li').removeClass('slick-active');
        $('.slider-testimonial .slick-dots li button').attr('aria-pressed', 'false').focus(function() {
            this.blur();
        });

    });


})(jQuery); // End of use strict

/* Close Video Overlay
================================================================================ */
$(document).keyup(function(e) {
    if (e.keyCode === 27) { close_video(); }
});

function close_video() {
    $('.video-overlay.open').removeClass('open');
    //$('.video-overlay video').get(0).pause();
    //$('.video-overlay video').remove();
    $('.video-overlay iframe').remove();
    $('.video-overlay video').remove();
    $('body').removeClass('layered');
};